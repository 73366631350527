import React from 'react'
import styled from 'styled-components'
import { BaseButton } from '../Section'

const DefaultBtn = styled(BaseButton)`
	color:#fff;	
	background-color:#033366;
	&:after {		
		background: #000033;
	}
	& .icon{
		> svg{
			fill:#fff;
		}
	}
`

const DefaultButton = (props) => {
	const { icon, text , size, textAfter} = props;
	return(
		<DefaultBtn className={`btn ${size ? `btn-${size}` : ''} `}>
			{textAfter ?
                (
					<>
						{ icon &&
							<span className='icon'>{icon}</span>
						}
						{ textAfter &&
							<span className='text'>{textAfter}</span>
						}
					</>
				):(
					<>
						{ text &&
							<span className='text'>{text}</span>
						}
						{ icon &&
							<span className='icon'>{icon}</span>
						}
					</>
				)
			}
		</DefaultBtn>
	)
}

export default DefaultButton