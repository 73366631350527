import * as React from "react"
import Layout from "../layouts"
import Seo from "../components/seo"
import { Link } from "gatsby"
import { NodataTitle, NodataDesc, NodataMedia, NotFoundContainer } from "../components/Nodata"
import ArrowRightIcon from "../components/Icons/ArrowRightIcon"
import { Section } from "../components/Section"
import ErrorImage from "../images/error.png"
import DefaultButton from "../components/Button/DefaultButton"

const NotFoundPage = ({ location }) => (
  <Layout location={location}>
    <Seo title="404 - Not found" />
    <Section pt="120px" pb="120px" xpt="60px" xpb="60px" mpt="40px" mpb="40px" bgColor="#fff">
      <div className="container">
        <NotFoundContainer>
          <NodataTitle className="h2">Page Not Found</NodataTitle>
          <NodataMedia>
            <img src={ErrorImage} alt="Buildings and More" />
          </NodataMedia>
          <NodataDesc mb="30px">
            <p>Sorry, we can’t find the page you are looking for click here to go back to the home page.</p>
            <Link to="/"><DefaultButton iconAfter={<ArrowRightIcon />} text="Go To Home" animate="ripple-black" /></Link>
          </NodataDesc>
        </NotFoundContainer>
      </div>
    </Section>
  </Layout>
)

export default NotFoundPage